$captcha-width: 88px;
$captcha-height: 88px;
$captcha-scale: 0.5;

.captcha {
  .exercise-information {
    margin: 15px 0 15px 0;


    .info-clickable-element {
      font-weight: bold;
      font-size: 1.357em;
    }

    .exercise {
      padding: 10px;
      background: #efefef;
    }

    .exercise-or {
      list-style-type: none;
      font-weight: bold;
    }
  }

  .grid {
    min-width: (($captcha-width + 6) * 3);

    .captchaContainer {
      float: left;
      position: relative;
      margin: 3px;
      cursor: pointer;

      &:nth-of-type(3n+1) {
        clear: none;
      }

      &:nth-of-type(4n+1) {
        clear: left;
      }

      @media (min-width: 640px) {
        &:nth-of-type(3n+1) {
          clear: none;
        }

        &:nth-of-type(4n+1) {
          clear: none;
        }

        &:nth-of-type(6n+1) {
          clear: left;
        }
      }

      .captchaSelected {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 30;
      }

      .captchaIcon {
        position: relative;
        display: inline-block;
        width: $captcha-width;
        height: $captcha-height;

        &.selected {
          @include scale($captcha-scale);
        }

        .background {
          display: block;
          position: absolute;
          background-repeat: no-repeat;
          z-index: 10;
          width: 100%;
          height: 100%;

        }

        .foreground {
          display: block;
          position: absolute;
          z-index: 20;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .actions {
    clear: both;
    margin-top: 20px;

    button {
      margin-bottom: 20px;
      @media (min-width: 468px) {
        margin-right: 20px;
      }

      @media (min-width: 768px) {
        margin-bottom: 0;
      }

      &.w-button-default {
        font-size: 0.7em;
      }
    }
  }
}
