//@import "flex";
@import "mixin";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "bootstrap";
@import "../components/captcha/styles/captcha.scss";
@import "wuppertal";

$wgreen: #99CC00;
$wblue: #004A99;
$werror: red;

html, body {
    margin: 0;
    height: 100%
}

body {
    font-size: 16px;
}

.w-outer-content {
    margin: 10px 20px;
    .w-content {
        margin-bottom: 20px;

        @media (min-width: 101rem) {
            margin-left: auto;
            margin-right: auto;
            max-width: 1350px;
            width: 100%;
        }
    }
}


/* centered columns styles */
.row-centered {
    text-align:center;
}
.col-centered {
    display:inline-block;
    float:none;
    /* reset the text-align */
    text-align:left;
    /* inline-block space fix */
    margin-right:-4px;
}

.col-fixed {
    /* custom width */
    width: 310px;
}
.col-min {
    /* custom min width */
    min-width:320px;
}
.col-max {
    /* custom max width */
    max-width:320px;
}

.outer-content{
    float: left;
    position: relative;
    margin: 4px 0;
}

@media(max-width:767px){
    .outer-content{
        padding-bottom: 33.33%;
    }
}
@media(min-width:768px){
    .outer-content{
        padding-bottom: 25%;
    }
}
@media(min-width:992px){
    .outer-content{
        padding-bottom: 16.66%;
        margin: 6px 0;
    }
}

.inner-content {
    position: absolute;
    left: 3px;
    right: 3px;
    top: 3px;
    bottom: 20px;

    a {
        color: #000000;
    }

    .img-candidate {
        width: 100%;
        height: 100%;
        border-top: 1px solid $wblue;
        border-left: 1px solid $wblue;
        border-right: 1px solid $wblue;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 35%;
    }
}

.mask {
    position:absolute;
    width: 100%;
    height: 20px;
    bottom: 0;
    background-color: #ffffff;
    opacity: 0.4;
    -moz-opacity: 0.6;
    z-index: 100;
}

.gallery-container{
    padding: 4px;
}

.inner-content:hover{
    border-color: $wblue;
    @include transition(0.4s);
}

.candidate-info {
    width: 350px;
}

.candidate-name {
    font-size: 100%;
    border-left: 1px solid $wblue;
    border-right: 1px solid $wblue;
    border-bottom: 1px solid $wblue;
    text-align: center;
    background-color: #EDEDED;;

    color: $wblue;
}

@media (max-width: 729px) {
    .candidate-name {
        font-size: 75%;
    }
}

/*@media (max-width: 768px) {*/
    /*.btn-responsive {*/
        /*padding:2px 4px;*/
        /*font-size:80%;*/
        /*line-height: 1;*/
        /*border-radius:3px;*/
    /*}*/
/*}*/

@media (max-width: 992px) {
    .btn-responsive {
        padding:4px 9px;
        font-size:90%;
        line-height: 1.2;
    }
}

@media (min-width: 768px) {
    .navbar > .container .navbar-brand,
    .navbar > .container-fluid .navbar-brand {
        margin-left: -10px;
    }
}

.info-space {
    margin-left: 10px;
}

.img-color{
    border: 1px solid $wblue;
}

.navbar-brand {
    color: $wblue !important;
}

.panel-group {
    margin-bottom: 5px;
}

.panel {
    background-color: #F6F4F5;
}

.form-row {
    margin-bottom: 10px;
}

.form-content {
    text-align: center;
    margin-top: 10px;
}

.form-control {
    padding: 3px 12px;
    font-size: 16px;
}

.label-filter {
    font-size: 80%;
    min-width: 80px;
}


@media (min-width: 768px) {
    .label-filter {
        font-size: 100%;
    }
}

section, \:section {
    display: block;
}

.vote-error {
    color: $werror;
}

.has-error {
    .control-label {
        color: $werror;
    }

    .form-control {
        border-color: $werror;
    }

    .help-block {
        color: #737373;
    }
}

.voting-form {
    margin-top: 20px;

    @media (max-width: 468px) {
        button {
            width: 100%;
        }
    }
}

.candidate {
    img {
        width: 70%;
    }
    .w-button {
        margin-bottom: 20px;

        @media (max-width: 468px) {
            display: block;
        }
    }

    @media (max-width: 768px) {
        .profile {
            margin-top: 20px;
        }
    }
}

.voting {
    img {
        border: 1px solid $wblue;
    }

    @media (max-width: 768px) {
        .hint {
            .w-button {
                font-size: 0.70em;
            }
        }
    }
}

.filter {

    .w-toggle {
        display: block;
        @media(min-width: 468px) {
            display: none;
        }
    }

    .toggle-content-show {
        display: block;
    }

    .toggle-content-hide {
        display: none;
        @media (min-width: 468px) {
            display: block;
        }
    }

    @media (min-width: 768px) {
        label {
            margin-right: 10px;
        }

        .form-group:nth-child(n+2) {
            margin: 0 10px;
        }
    }
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}