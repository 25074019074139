/**
 * Font-Stacks
 */
@font-face {
  font-family: Muli;
  font-style: normal;
  font-weight: 300;
  src: url("fonts/Muli-Light.woff2") format("woff2"), url("fonts/Muli-Light.woff") format("woff"), url("fonts/Muli-Light.ttf") format("truetype");
}

@font-face {
  font-family: Muli;
  font-style: normal;
  font-weight: 400;
  src: url("fonts/Muli.woff2") format("woff2"), url("fonts/Muli.woff") format("woff"), url("fonts/Muli.ttf") format("truetype");
}

@font-face {
  font-family: Muli;
  font-style: italic;
  font-weight: 400;
  src: url("fonts/Muli-Italic.woff2") format("woff2"), url("fonts/Muli-Italic.woff") format("woff"), url("fonts/Muli-Italic.ttf") format("truetype");
}

@font-face {
  font-family: Muli;
  font-style: normal;
  font-weight: 700;
  src: url("fonts/Muli-Bold.woff2") format("woff2"), url("fonts/Muli-Bold.woff") format("woff"), url("fonts/Muli-Bold.ttf") format("truetype");
}

@font-face {
  font-family: Muli;
  font-style: italic;
  font-weight: 700;
  src: url("fonts/Muli-BoldItalic.woff2") format("woff2"), url("fonts/Muli-BoldItalic.woff") format("woff"), url("fonts/Muli-BoldItalic.ttf") format("truetype");
}

body {
  /* stylelint-disable-line selector-max-type */
  background: #FFFFFF;
  color: #444444;
  font: 1em/1.5em Muli, sans-serif;

  @media (min-width: 30rem) {
    font-size: 1.125em;
  }
}

a {
  color: #004A99;
}

a:hover {
  color: #00346b;
  text-decoration: none;
}

/* Wuppertal */
.w-headline {
  position: relative;
  font-size: 1em;
  margin: 0;
  overflow: hidden;

  .w-headline-inner {
    border-bottom: 2px solid #004A99;
    color: #333333;
    display: block;
    font-size: 1.35em;
    font-weight: 400;
    line-height: 1.125em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    padding-bottom: 0.25em;
    //padding-right: 1.5em;
    position: relative;
  }

  @media (min-width: 30rem) {
    font-size: 1.26em;
  }
}

.w-collapse-section {

  .w-collapsable-trigger {
    cursor: pointer;

    .w-collapsable-inner {
      border-bottom: 2px solid #004A99;
      color: #333333;
      display: block;
      font-size: 1.35em;
      font-weight: 400;
      line-height: 1.125em;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      padding-bottom: 0.25em;
      padding-right: 1.5em;
      position: relative;

      @media (min-width: 30rem) {
        font-size: 1.5em;
      }
    }
  }

  .w-icon {
    right: 0;
    height: 1em;
    position: absolute;
    width: 1em;
    @include transition(transform 0.1s ease-out);
  }

  .w-collapsed {
    .w-icon {
      @include rotate(180deg);
    }
  }
}

.w-back {
  color: #004A99;
  fill: currentColor;
  .w-back-icon {
    @include rotate(180deg);
    right: 0;
    width: 1em;
    height: 1em;
    position: absolute;
    top: 0.09375em;
  }
}

.w-text {
    padding: 0 10px;
}

.w-jumbo-button {
  align-items: center;
  background-color: #004A99;
  background-image: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.2) 100%);
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
  color: #FFFFFF;
  display: flex;
  fill: currentColor;
  margin-bottom: 2rem;
  margin-right: 10px;
  min-height: 150px;
  overflow: hidden;
  padding: 1.5rem 130px 1.5rem 1.5rem;
  position: relative;
  text-decoration: none;
  clear: both;

  &:hover {
    color: #ffffff;

    .w-icon {
      @include scale(1.25)
    }
  }

  .w-button-headline {
    font: 400 1.5em/1.125em Muli, sans-serif;
    margin-top: 0;
    margin-bottom: 0;
    text-transform: uppercase;

    @media (min-width: 40rem) {
      font-size: 1.6875em;
    }

    + .w-info {
      margin-top: 1em;
    }
  }
}

.w-info {
  font-family: Muli, sans-serif;
  font-weight: 300;

  @media (min-width: 40rem) {
    font-size: 1.125em;
  }
}

.footer {
  background-color: #00438a;
}

.w-linklist {
    list-style: none;
    margin: 0;
    padding: 0;

  &.w-inline-footer {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;

    a {
      color: #FFFFFF;
      text-decoration: none;
      font-size: 0.9em;

      &:hover {
        text-decoration: underline;
      }
    }

    .w-list-item {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 0.5rem;
        &::after {
          color: #FFFFFF;
          content: "|";
          margin-left: 0.5rem;
        }
      }
    }
  }
}

.w-icon {
  height: 100px;
  margin-top: -50px;
  position: absolute;
  right: 25px;
  top: 50%;
  width: 100px;
  @include transition(transform 0.15s ease);
}

.w-button {
  /* stylelint-disable */
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /* stylelint-enable */
  border-radius: 6px;
  display: inline-block;
  font: 1em/20px Muli, sans-serif;
  letter-spacing: 1px;
  margin: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  @include transition(all 0.3s ease);
  vertical-align: middle;

  &:hover, &:focus, &:not(:disabled) {
    text-decoration: none;
  }

  &.w-button-default {
    background-color: #d4d4d4;
    border: none;
    @include box-shadow(0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2));
    color: #444444;;
    fill: currentColor;
    padding: 0.625em 1em;

    &:not(:disabled):hover, &:focus {
      background: #e0e0e0;
      @include box-shadow(0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2));
      color: #444444;;
    }

    &:disabled {
      cursor: not-allowed;
      /* stylelint-disable-line */
      background-color: #666666;
    }
  }

  &.w-button-primary {
    background-color: #004A99;
    border: none;
    @include box-shadow(0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2));
    color: #FFFFFF;
    fill: currentColor;
    padding: 0.625em 1em;

    &:not(:disabled):hover, &:focus {
      background: #00346b;
      @include box-shadow(0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2));
      color: #FFFFFF;
    }

    &:disabled {
      cursor: not-allowed;
      /* stylelint-disable-line */
      background-color: #666666;
    }
  }

  &.w-button-icon-right, &.w-button-icon-left {
    .w-button-icon {
      top: 2px;
      margin-top: 0.625em;
    }
  }

  &.w-button-icon-left .w-button-icon {
    left: 0;
    height: 1em;
    position: absolute;
    width: 1em;
    margin-left: 0.7em;
  }

  &.w-button-icon-right .w-button-icon {
    margin-right: 0.7em;
  }

  &.w-button-icon-right {
    padding-right: 2.5em;
  }

  &.w-button-icon-left {
    padding-left: 2.5em;
  }
}

.w-toggle {
  border-bottom: 1px solid #444444;
  color: #333333;
  display: block;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1.125em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding-bottom: 0.25em;
  position: relative;
  cursor: pointer;

  @media (min-width: 30rem) {
    font-size: 1.5em;
  }

  &.w-toggle-open {
    .w-back-icon {
      @include rotate(270deg);
    }
  }

  &.w-toggle-closed {
    .w-back-icon {
      @include rotate(90deg);
    }
  }

  .w-back {
    color: #333333;
    .w-back-icon {
      @include transition(transform 0.1s ease-out);
    }
  }
}

.hotline {
  font-size: 1.35rem;
}

.w-box {
  background-color: rgb(237, 237, 237);
  margin-right: 10px;
  padding: 0.75rem 1.25rem 1.5rem;
  clear: both;

  .w-box-title {
    color: rgb(85, 85, 85);
    font-size: 1.25em;
    font-weight: 700;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 0.5em;
  }

  .w-box-icon {
    height: 1em;
    width: 1em;
    fill: currentcolor;
    @include scale(1.75);
    margin-top: 0.25em;
    margin-left: 0.5em;
    margin-right: 0.75em;
  }
}